.form {
  position: relative;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.cardForm {
  width: 290px;
}

.halfField {
  width: 134px;
}

.payBtn.payBtn {
  margin: 24px 0 12px 0;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  background: #49A7E4;
  box-shadow: none;
  &:hover {
    background: #3693D0;
  }
}
