.header {
  box-sizing: border-box;
  width: 100%;
  color: #000;
  background: #fff;
  padding: 30px 32px 18px;
  //@media (max-width: 370px) {
  //  padding: 18px 24px;
  //}
}

.amount {
  font-size: 26px;
  font-weight: bold;
  margin-top: 16px;
}

.currency {
  //font-size: 16px;
  //margin-left: 4px;
}

.order {
  font-size: 16px;
  margin: 6px 0;
}

.description {
  font-size: 14px;
  margin: 6px 0;
}
