@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pageWrapper {
  background: #fff;
  min-height: 100vh;
  min-width: 100vw;
}

.page.page {
  width: 380px;
  margin: 0 auto 0 auto;
  padding: 48px 0 36px 0;
  min-height: 420px;
  //border-radius: 14px;
  //box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
  //@media (max-width: 430px) {
  //  width: auto;
  //  height: 100%;
  //  margin: 0;
  //  min-height: 100vh;
  //  border-radius: 0;
  //}
  animation-duration: 0.35s;
  animation-name: animate-fade;
  animation-delay: 0.35s;
  animation-fill-mode: backwards;
  background: #fff;
}

.content.content {
  padding: 12px 16px 20px 16px;
}

.preloaderWrapper {
  z-index: 10;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.preloader.preloader {
  color: #3f51b5;
}

.preloaderText {
  //color: #3f51b5;
  color: #3f51b5;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: 52px;
}

.info {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin: 18px 0 18px 0;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 60px;
}

.status {
  display: flex;
  justify-content: center;
  margin: 12px 0 24px;

  .success {
    font-size: 128px;
    color: #4caf50;
  }

  .pending {
    color: #1976d2;
    margin: 9px 0 12px;
  }

  .fail {
    font-size: 128px;
    color: #f44336;
  }

  .refund {
    font-size: 128px;
    color: #4caf50;
  }

  .warning {
    font-size: 128px;
    color: #ffa726;
  }
}

.debug {
  color: #bdbdbd;
  font-size: 12px;
  max-width: 712px;
  margin: 36px auto 24px auto;
  padding: 0 6px;
  box-sizing: border-box;
}

.version {
  display: flex;
}
