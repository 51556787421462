
.icon {
  width: 64px;
  height: 64px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &.error {
    background-image: url("../../assets/images/error.png");
  }

  &.success {
    background-image: url("../../assets/images/success.png");
  }

  &.wait {
    background-image: url("../../assets/images/wait.png");
  }
}
