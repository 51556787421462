
@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.page.page {
  width: 380px;
  margin: 48px auto 36px auto;
  min-height: 420px;
  border-radius: 14px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
  @media (max-width: 430px) {
    width: auto;
    height: 100%;
    margin: 0;
    min-height: 100vh;
    border-radius: 0;
  }
  animation-duration: 0.35s;
  animation-name: animate-fade;
  animation-delay: 0.35s;
  animation-fill-mode: backwards;
}

.content.content {
  padding: 12px 32px 20px 32px;
}

.preloaderWrapper {
  z-index: 10;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.preloader.preloader {
  color: #3f51b5;
}

.preloaderText {
  //color: #3f51b5;
  color: #3f51b5;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: 52px;
}
